<template>
	<div class="loader loader--style2" title="1">
		<svg
			version="1.1"
			id="loader-1"
			xmlns="http://www.w3.org/2000/svg"
			xmlns:xlink="http://www.w3.org/1999/xlink"
			x="0px"
			y="0px"
			width="50px"
			height="50px"
			viewBox="0 0 50 50"
			style="enable-background: new 0 0 50 50"
			xml:space="preserve"
		>
			<path
				fill="#FFF"
				d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z"
			>
				<animateTransform
					attributeType="xml"
					attributeName="transform"
					type="rotate"
					from="0 25 25"
					to="360 25 25"
					dur="0.6s"
					repeatCount="indefinite"
				/>
			</path>
		</svg>
	</div>
</template>

<script>
// Dependency Imports
import {} from 'vue';

//Component Imports

export default {
	name: 'LargeLoader',
	components: {},

	setup() {
		return {};
	},
};
</script>

<style scoped>
.loader {
	height: 100%;
	width: 60px;
	border: solid blue 0px;
	text-align: center;
	padding: 0em;
	margin: 6px 0px 0px 0px;
	display: inline-block;
	vertical-align: top;
}
</style>
