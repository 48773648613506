<template>
	<section id="mainView">
		<form class="login-form" @submit.prevent="submitLogin()">
			<h2 class="text-gradient">Log In</h2>
			<div class="container">
				<div class="title-cont">Username*</div>
				<input
					class="text-input"
					autocomplete="username"
					type="username"
					placeholder="username"
					v-model="username"
				/>
			</div>
			<div class="container">
				<div class="title-cont">Password*</div>
				<input
					class="text-input"
					autocomplete="current-password"
					type="password"
					placeholder="password"
					v-model="password"
				/>
			</div>
			<div class="submit-field">
				<button type="submit" value="Send" class="submit-button">
					<LargeLoader v-if="isLoading" />
					<span v-else class="submit-text">Go</span>
				</button>
			</div>
		</form>
	</section>
</template>

<script>
// Dependency Imports
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { smoothScrollTop } from '@/plugins/utilities';
import { api } from '@/plugins/wire-config.js';
import { mainStore } from '@/store/main';

// Component Imports
import LargeLoader from '@/components/reusables/LargeLoader.vue';

export default {
	name: 'Home',
	components: {
		LargeLoader,
	},

	setup() {
		let router = useRouter();

		const isLoading = ref(false);
		const username = ref('');
		const password = ref('');

		async function submitLogin() {
			isLoading.value = true;
			let loginData = {
				username: username.value,
				password: password.value,
			};

			let response = await api.post('/login', loginData, (err) => {
				console.error(err);
			});

			if (response._ok) {
				router.push({ name: 'AdminPanel' });
			} else {
				username.value = '';
				password.value = '';
				alert(response._message);
			}

			isLoading.value = false;
		}

		// Setup Returns
		return {
			smoothScrollTop,
			username,
			password,
			submitLogin,
			isLoading,
		};
	},

	async beforeRouteEnter(to, from, next) {
		await mainStore.updateSessionData();
		if (mainStore.getState().session.isAuth) {
			next({ name: 'AdminPanel' });
		} else {
			next();
		}
	},
};
</script>

<style scoped>
#mainView {
	/* border: solid yellow 1px; */
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	height: auto;
	min-height: 100%;
	position: relative;
	z-index: 0;
}

.login-form {
	position: relative;
	width: 90%;
	max-width: 500px;
	height: 100%;
	min-height: 600px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 40px;
	background: rgba(255, 255, 255, 0.5);
	background: var(--card-gradient-contrast);
	box-shadow: var(--card-shadow);
	color: var(--text-color);
	border: solid green 0px;
	margin: 40px 0px 0px 0px;
	backdrop-filter: blur(10px);
}

.container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	margin: 20px;
	max-width: 300px;
	width: 80%;
	color: var(--text-color);
}

.title-cont {
	margin: 8px 2px 8px 2px;
}

.text-input {
	border-radius: 12px;
	height: 44px;
	width: 100%;
	border: solid 2px rgba(124, 69, 217, 0);
	padding: 10px 12px 10px 12px;
	transition-duration: 300ms;
	font-size: 18px;
	box-shadow: 0px 4px 10px rgba(190, 190, 190, 0.2);
	background: rgba(255, 255, 255, 1);
}

.text-input:focus {
	border-radius: 12px;
	border: solid 2px rgba(160, 75, 237, 1);
	outline: none;
	box-shadow: 0px 4px 18px rgba(150, 150, 150, 0.4);
}

.text-input::placeholder {
	color: rgba(0, 0, 0, 0.3);
}

.text-gradient {
	font-size: 44px;
	margin: 0px 0px 10px 0px;
	background: linear-gradient(
		145deg,
		rgba(18, 178, 255, 1) 0%,
		rgba(197, 58, 255, 1) 100%
	);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.submit-field {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.submit-button {
	max-width: 180px;
	width: 90%;
	height: 60px;
	font-size: 18px;
	font-weight: bold;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	border-radius: 60px;
	margin: 40px;
	color: rgba(255, 255, 255, 0.9);
	background: linear-gradient(
		145deg,
		rgba(0, 172, 255, 1) 0%,
		rgba(179, 0, 255, 1) 100%
	);
	background-size: 200%;
	box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
	transition: cubic-bezier(0.56, 0, 0.39, 2.5) 230ms;
	border: none;
}

.submit-button:focus {
	border: solid white 2px;
	outline: none;
}

.submit-button:hover {
	transform: translateY(-3px);
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.15);
}

.submit-button:active {
	transform: translateY(2px);
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
}

/* Animations */

@keyframes heroImageBounce {
	0% {
		transform: translateY(-4px) rotate(0.5deg);
	}
	50% {
		transform: translateY(4px) rotate(-0.5deg);
	}
	100% {
		transform: translateY(-4px) rotate(0.5deg);
	}
}

@media only screen and (max-width: 800px) {
}
</style>
